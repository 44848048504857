import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import './scss/main.scss'
import Vue2Filters from 'vue2-filters'

Vue.use(Vue2Filters)

Vue.config.productionTip = false

Vue.filter('percentage', function (value, decimals = 0) {
  return new Intl.NumberFormat('hr-HR', {
    style: 'percent',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(value)
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
