export const depr = [
  {
    min: 0,
    percentage: 96
  },
  {
    min: 1,
    percentage: 93
  },
  {
    min: 2,
    percentage: 90
  },
  {
    min: 3,
    percentage: 88
  },
  {
    min: 4,
    percentage: 86
  },
  {
    min: 5,
    percentage: 84
  },
  {
    min: 6,
    percentage: 82
  },
  {
    min: 7,
    percentage: 81
  },
  {
    min: 8,
    percentage: 80
  },
  {
    min: 9,
    percentage: 79
  },
  {
    min: 10,
    percentage: 78
  },
  {
    min: 11,
    percentage: 77
  }, {
    min: 12,
    percentage: 76.04
  },
  {
    min: 13,
    percentage: 75.08
  },
  {
    min: 14,
    percentage: 74.12
  },
  {
    min: 15,
    percentage: 73.16
  },
  {
    min: 16,
    percentage: 72.20
  },
  {
    min: 17,
    percentage: 71.24
  },
  {
    min: 18,
    percentage: 70.28
  },
  {
    min: 19,
    percentage: 69.32
  },
  {
    min: 20,
    percentage: 68.36
  },
  {
    min: 21,
    percentage: 67.40
  },
  {
    min: 22,
    percentage: 66.44
  },
  {
    min: 23,
    percentage: 65
  },
  {
    min: 24,
    percentage: 63.58
  },
  {
    min: 26,
    percentage: 62.16
  },
  {
    min: 28,
    percentage: 60.74
  },
  {
    min: 30,
    percentage: 59.32
  },
  {
    min: 32,
    percentage: 57.90
  },
  {
    min: 34,
    percentage: 56.48
  },
  {
    min: 36,
    percentage: 55.06
  },
  {
    min: 38,
    percentage: 53.64
  },
  {
    min: 40,
    percentage: 52.22
  },
  {
    min: 42,
    percentage: 50.80
  },
  {
    min: 44,
    percentage: 49.38
  },
  {
    min: 46,
    percentage: 47.96
  }, {
    min: 48,
    percentage: 46.36
  },
  {
    min: 51,
    percentage: 44.76
  },
  {
    min: 54,
    percentage: 43.16
  },
  {
    min: 57,
    percentage: 41.56
  },
  {
    min: 60,
    percentage: 40.06
  },
  {
    min: 64,
    percentage: 38.56
  },
  {
    min: 68,
    percentage: 37.06
  },
  {
    min: 72,
    percentage: 35.26
  },
  {
    min: 78,
    percentage: 33.46
  },
  {
    min: 84,
    percentage: 31.66
  },
  {
    min: 90,
    percentage: 29.86
  },
  {
    min: 96,
    percentage: 28.06
  }, {
    min: 102,
    percentage: 26.26
  },
  {
    min: 108,
    percentage: 24.46
  },
  {
    min: 114,
    percentage: 22.66
  },
  {
    min: 120,
    percentage: 21.72
  },
  {
    min: 132,
    percentage: 21.12
  },
  {
    min: 144,
    percentage: 20.52
  },
  {
    min: 156,
    percentage: 19.92
  },
  {
    min: 168,
    percentage: 19.32
  },
  {
    min: 180,
    percentage: 18.72
  },
  {
    min: 192,
    percentage: 18.12
  },
  {
    min: 204,
    percentage: 17.52
  },
  {
    min: 216,
    percentage: 16.92
  },
  {
    min: 228,
    percentage: 16.32
  },
  {
    min: 240,
    percentage: 15.72
  },
  {
    min: 252,
    percentage: 15.12
  },
  {
    min: 264,
    percentage: 14.52
  },
  {
    min: 276,
    percentage: 13.92
  },
  {
    min: 288,
    percentage: 13.32
  },
  {
    min: 300,
    percentage: 12.72
  },
  {
    min: 312,
    percentage: 12.12
  },
  {
    min: 324,
    percentage: 11.52
  },
  {
    min: 336,
    percentage: 10.92
  },
  {
    min: 348,
    percentage: 10.32
  }
]
