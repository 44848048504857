<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-col xl="11">
      <h1 class="display-1 font-weight-medium text-center">
        Kalkulator upravne pristojbe
      </h1>

      <div
        class="text-center white--text mt-6"
      >
        Od 01.01.2018., dosadašnji porez na stjecanje rabljenog automobila zamjenjuje <strong>upravna pristojba</strong>
        koja ovisi o snazi i starosti vozila. Plaća se u istim slučajevima kao i do sada, odnosno prilikom kupnje rabljenog
        automobila (iz uvoza ili u RH) u čijoj cijeni nije iskazan PDV ili trgovac ne plaća PDV na maržu.
      </div>

      <v-row
        no-gutters
        justify="center"
        class="mt-5"
      >
        <v-col
          xl="5"
        >
          <v-form>
            <div class="font-weight-bold body-2 mb-1">
              Starost vozila u godinama
            </div>
            <v-text-field
              v-model="age"
              light
              solo
              autocomplete="off"
              type="number"
            >
              <span
                slot="append"
                class="grey--text text--darken-2"
              >g</span>
            </v-text-field>

            <div class="font-weight-bold body-2 mb-1">
              Snaga vozila
            </div>
            <v-row no-gutters>
              <v-col cols="8">
                <v-text-field
                  v-model="power"
                  light
                  solo
                  autocomplete="off"
                  type="number"
                />
              </v-col>
              <v-col
                cols="4"
                class="pl-1"
              >
                <v-select
                  v-model="kworks"
                  :items="[ { text: 'KW', value: 'kw' }, { text: 'KS', value: 'ks' } ]"
                  solo
                  light
                />
              </v-col>
            </v-row>
          </v-form>

          <v-divider />

          <div class="text-center my-6">
            <div class="headline font-weight-bold">
              {{ pristojba.raw | currency('kn', 2, { 'thousandsSeparator': '.', 'decimalSeparator': ',', 'symbolOnLeft': false, 'spaceBetweenAmountAndSymbol': true }) }}
            </div>
            <div class="subheading">
              Upravna pristojba
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { knpokw } from './../data/uprav'

export default {
  name: 'App',
  components: {},
  data () {
    return {
      age: null,
      power: null,
      kworks: 'kw',
      value: null
    }
  },
  computed: {
    pristojba () {
      let kw = this.power
      let taxperkw = 0

      for (const item of knpokw) {
        if (this.age >= item.min) taxperkw = item.value
      }

      if (this.kworks === 'ks') {
        kw = Math.round(this.power / 1.36)
      }

      return {
        raw: kw * taxperkw
      }
    },
    oldtaxraw () {
      return this.value * 0.05
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {}

}
</script>
