<template>
  <v-app>
    <v-main>
      <v-container class="py-6">
        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            xl="5"
          >
            <v-tabs
              class="mb-10"
              dark
              color="white"
              background-color="transparent"
              grow
              show-arrows
            >
              <v-tab :to="'/'">
                <span v-if="$vuetify.breakpoint.xsOnly">Trošarina na unos</span>
                <span v-else>Kalkulator trošarine na unos automobila</span>
              </v-tab>
              <v-tab :to="'/upravna-pristojba'">
                <span v-if="$vuetify.breakpoint.xsOnly">Upravna pristojba</span>
                <span v-else>Kalkulator upravne pristojbe</span>
              </v-tab>
            </v-tabs>

            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!--    <v-snackbar-->
    <!--      v-model="installSnack"-->
    <!--      :timeout="20000"-->
    <!--      multi-line-->
    <!--      bottom-->
    <!--    >-->
    <!--      Želiš li koristiti Uvoz Auta kao aplikaciju?-->
    <!--      <v-btn-->
    <!--        color="pink"-->
    <!--        flat-->
    <!--        @click="install"-->
    <!--      >-->
    <!--        Da, instaliraj-->
    <!--      </v-btn>-->
    <!--    </v-snackbar>-->
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  })
}
</script>
