<template>
  <span>
    <span
      v-if="opposite"
      class="text--secondary font-weight-regular"
      style="opacity: 0.4"
    >({{ valueHrk | currency('kn', 2, { 'thousandsSeparator': '.', 'decimalSeparator': ',', 'symbolOnLeft': false, 'spaceBetweenAmountAndSymbol': true }) }})</span>
    {{ valueHrk / 7.5345 | currency('€', 2, { 'thousandsSeparator': '.', 'decimalSeparator': ',', 'symbolOnLeft': false, 'spaceBetweenAmountAndSymbol': true }) }}
    <span
      v-if="!opposite"
      class="text--secondary"
      style="opacity: 0.4"
    >({{ valueHrk | currency('kn', 2, { 'thousandsSeparator': '.', 'decimalSeparator': ',', 'symbolOnLeft': false, 'spaceBetweenAmountAndSymbol': true }) }})</span>
  </span>
</template>

<script>
export default {
  name: 'EurHrkValueHelper',
  props: {
    valueHrk: {
      type: Number,
      default: 0
    },
    opposite: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
