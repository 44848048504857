export const knpokw = [
  {
    min: 0,
    value: 50
  }, {
    min: 2,
    value: 45
  }, {
    min: 3,
    value: 40
  }, {
    min: 4,
    value: 35
  }, {
    min: 5,
    value: 30
  }, {
    min: 6,
    value: 25
  }, {
    min: 7,
    value: 20
  }, {
    min: 8,
    value: 15
  }, {
    min: 11,
    value: 10
  }, {
    min: 15,
    value: 5
  }, {
    min: 19,
    value: 3
  }, {
    min: 20,
    value: 1
  }, {
    min: 30,
    value: 0
  }
]
