export const tax = {
  old: {
    pc: [
      { min: 0, vn: 0, tax: 0 },
      { min: 100000, vn: 0, tax: 0 },
      { min: 150000, vn: 2000, tax: 3 },
      { min: 200000, vn: 3500, tax: 5 },
      { min: 250000, vn: 6000, tax: 7 },
      { min: 300000, vn: 9500, tax: 9 },
      { min: 350000, vn: 14000, tax: 11 },
      { min: 400000, vn: 19500, tax: 13 },
      { min: 450000, vn: 26000, tax: 14 },
      { min: 500000, vn: 33000, tax: 15 },
      { min: 550000, vn: 40500, tax: 16 },
      { min: 600000, vn: 48500, tax: 17 }
    ],
    co2: {
      d: [
        { min: 70, on: 185, perg: 55 },
        { min: 85, on: 1010, perg: 175 },
        { min: 120, on: 7135, perg: 1150 },
        { min: 140, on: 30135, perg: 1250 },
        { min: 170, on: 67635, perg: 1350 },
        { min: 200, on: 108135, perg: 1450 }
      ],
      b: [
        { min: 75, on: 95, perg: 35 },
        { min: 90, on: 620, perg: 135 },
        { min: 120, on: 4670, perg: 450 },
        { min: 140, on: 13670, perg: 700 },
        { min: 170, on: 34670, perg: 1200 },
        { min: 200, on: 70670, perg: 1300 }
      ]
    }
  },
  new: {
    pc: [
      { min: 0, vn: 0, tax: 0 },
      { min: 100000, vn: 0, tax: 0 },
      { min: 150000, vn: 0, tax: 0 },
      { min: 200000, vn: 3000, tax: 3 },
      { min: 250000, vn: 4500, tax: 5 },
      { min: 300000, vn: 7000, tax: 7 },
      { min: 350000, vn: 10500, tax: 9 },
      { min: 400000, vn: 15000, tax: 11 },
      { min: 450000, vn: 20500, tax: 13 },
      { min: 500000, vn: 27000, tax: 15 },
      { min: 550000, vn: 34500, tax: 16 },
      { min: 600000, vn: 42500, tax: 17 }
    ],
    co2: {
      d: [
        { min: 95, on: 85, perg: 105 },
        { min: 125, on: 3235, perg: 185 },
        { min: 155, on: 8785, perg: 1100 },
        { min: 190, on: 47285, perg: 1250 },
        { min: 215, on: 78535, perg: 1350 },
        { min: 255, on: 132535, perg: 1550 }
      ],
      b: [
        { min: 95, on: 25, perg: 45 },
        { min: 125, on: 1375, perg: 140 },
        { min: 155, on: 5575, perg: 555 },
        { min: 175, on: 16675, perg: 725 },
        { min: 200, on: 34800, perg: 975 },
        { min: 240, on: 73800, perg: 1650 }
      ]
    }
  }
}
