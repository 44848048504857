<template>
  <v-row
    no-gutters
    justify="center"
  >
    <v-col xl="11">
      <h1 class="text-h5 text-lg-h4 font-weight-medium text-center">
        Kalkulator trošarine na unos automobila <strong>od 2024.</strong>
      </h1>

      <div class="text-center mt-6 body-2">
        Vrijedi za M1 - osobne automobile.
      </div>

      <v-row
        no-gutters
        justify="center"
        class="mt-10 mb-14"
      >
        <v-col
          sm="9"
        >
          <v-form novalidate>
            <v-row
              class="mb-8"
              no-gutters
            >
              <v-col>
                <v-btn
                  :outlined="type !== 'rabljeno'"
                  color="white"
                  light
                  large
                  block
                  @click="type = 'rabljeno'"
                >
                  Rabljeno Vozilo
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  :outlined="type !== 'novo'"
                  color="white"
                  light
                  large
                  block
                  @click="type = 'novo'"
                >
                  Novo Vozilo
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="body-2 font-weight-bold mb-1">
                  Vrsta goriva
                </div>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-btn
                      :outlined="fuel !== 'd'"
                      color="white"
                      large
                      light
                      block
                      @click="fuel = 'd'"
                    >
                      Dizel
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      :outlined="fuel !== 'b'"
                      color="white"
                      large
                      light
                      block
                      @click="fuel = 'b'"
                    >
                      Benzin
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div class="body-2 font-weight-bold mb-1">
                  Količina CO2 u ispuhu
                </div>
                <v-text-field
                  v-model="co2"
                  placeholder="129"
                  solo
                  light
                  background-color="white"
                  type="number"
                  autocomplete="nope"
                  hint="Upišite službeni podatak o količini CO2 u ispuhu"
                >
                  <span
                    slot="append"
                    class="grey--text text--darken-2"
                  >g/km</span>
                </v-text-field>
              </v-col>
            </v-row>

            <div class="font-weight-bold body-2 mb-1">
              Prodajna cijena novog vozila &middot; <a
                href="https://carina.gov.hr/trosarinsko-postupanje/dodatne-informacije-o-trosarinama-i-posebnim-porezima/posebni-porez-na-motorna-vozila-3650/informativni-kalkulator-za-izracun-ppmv-a/prodajne-cijene-od-01-07-2013/4841"
                target="_blank"
              >Službeni cjenici Carine</a>
            </div>
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-col>
                <v-text-field
                  v-model="new_value_input"
                  light
                  solo
                  type="text"
                  inputmode="numeric"
                  autocomplete="nope"
                  hint="Upišite maloprodajnu cijenu novog vozila s uključenim PDV-om i carinom, bez posebnog poreza. Za rabljeno vozilo upisuje se njegova prodajna cijena dok se prodavao nov."
                >
                  <span slot="hint" />
                </v-text-field>
              </v-col>
              <v-col
                cols="auto"
                class="pl-1"
              >
                <v-select
                  v-model="newValueCurrency"
                  style="width: 80px"
                  :items="[ { text: '€', value: 'EUR' }, { text: 'kn', value: 'HRK' }]"
                  solo
                  light
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div
                  class="font-weight-bold body-2 mb-1"
                >
                  Mjesec i godina prve
                  registracije
                </div>
                <v-row no-gutters>
                  <v-col
                    cols="5"
                    class="pr-2"
                  >
                    <v-select
                      ref="monthInput"
                      v-model="ageMonth"
                      :items="months"
                      solo
                      light
                      placeholder="01"
                      @change="$refs.yearInput.focus(); $refs.yearInput.isMenuActive = true"
                    />
                  </v-col>
                  <v-col
                    cols="7"
                    class="pl-2"
                  >
                    <v-select
                      ref="yearInput"
                      v-model="ageYear"
                      :items="years"
                      solo
                      light
                      placeholder="2014"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                sm="6"
              >
                <div
                  :class="{ 'text--disabled': type !== 'rabljeno' }"
                  class="font-weight-bold body-2 mb-1"
                >
                  Vrijednost rabljenog vozila
                </div>
                <v-text-field
                  v-model="rest_value_input"
                  :disabled="type !== 'rabljeno'"
                  light
                  placeholder=""
                  solo
                  type="text"
                  inputmode="numeric"
                  hint="Trenutna tržišna vrijednost rabljenog vozila (u EUR)"
                >
                  <span
                    slot="append"
                    class="grey--text text--darken-2"
                  >€</span>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-divider />

          <div class="text-center my-6">
            <div class="headline font-weight-bold">
              <eur-hrk-value-helper :value-hrk="tax.finalTax" />
            </div>
            <div class="subheading">
              Posebni porez na motorna vozila
            </div>
            <div
              v-if="newOrOldCalculation === 'new'"
              class="caption text--secondary"
            >
              Izračunato za vozila prve registracije od 01.01.2021.
            </div>
            <div
              v-else
              class="caption text--secondary"
            >
              Izračunato za vozila prve registracije do 31.12.2020.
            </div>
          </div>

          <v-row
            no-gutters
            class="mb-1"
          >
            <v-col>
              Eco komponenta
            </v-col>
            <v-col
              cols="auto"
              class="text-right text-no-wrap"
            >
              <eur-hrk-value-helper
                :value-hrk="tax.ecoTax"
                opposite
              />
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="mb-1"
          >
            <v-col>
              Vrijednosna komponenta
            </v-col>
            <v-col
              cols="auto"
              class="text-right text-no-wrap"
            >
              <eur-hrk-value-helper
                :value-hrk="tax.priceTax"
                opposite
              />
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="mb-1"
          >
            <v-col>
              Ukupno
            </v-col>
            <v-col
              cols="auto"
              class="text-right text-no-wrap"
            >
              <eur-hrk-value-helper
                :value-hrk="tax.taxSum"
                opposite
              />
            </v-col>
          </v-row>

          <v-row
            no-gutters
            :class="{ 'text--disabled': type !== 'rabljeno' }"
            class="mb-1"
          >
            <v-col>
              Umanjenje za rabljeno vozilo
            </v-col>
            <v-col
              cols="auto"
              class="text-right"
            >
              - {{ (tax.depr) | percentage(2) }}
            </v-col>
          </v-row>

          <v-divider class="my-6" />

          <v-row
            no-gutters
          >
            <v-col class="font-weight-bold">
              Konačno
            </v-col>
            <v-col
              cols="auto"
              class="text-right font-weight-bold text-no-wrap"
            >
              <eur-hrk-value-helper
                :value-hrk="tax.finalTax"
                opposite
              />
            </v-col>
          </v-row>

          <v-divider class="my-6" />

          <div class="body-1 mb-3">
            Našli ste rabljeno vozilo? <strong><a
              href="https://www.carvertical.com/hr/landing/v3?a=netform&b=3c23c39&voucher=uvozauta"
              target="_blank"
            >CarVertical</a></strong> omogućava vrlo pouzdanu <strong>provjeru servisne povijesti vozila i stanja kilometara</strong>.
            Ispod upišite broj šasije vozila za besplatnu pretprovjeru o tome koji su zapisi povijesti dostupni:
          </div>

          <v-alert
            dark
            class="title"
            color="grey darken-4"
          >
            Uvoz Auta je za vas osigurao <strong><a
              href="https://www.carvertical.com/hr/landing/v3?a=netform&b=3c23c39&voucher=uvozauta"
              target="_blank"
            >20% popusta na CarVertical provjere</a></strong>. Iskoristite odmah!
          </v-alert>

          <div class="elevation-5">
            <div
              data-cvaff
              data-locale="hr"
              data-a="netform"
              data-b="3c23c39"
              data-voucher="uvozauta"
              data-integration-type="banner"
              style="width: 100%; height: 350px"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { tax } from '@/data/tax'
import { depr } from '@/data/depr'
import moment from 'moment'
import EurHrkValueHelper from '@/views/EurHrkValueHelper.vue'

export default {
  components: { EurHrkValueHelper },
  data () {
    return {
      type: null,
      fuel: null,
      co2: null,
      new_value: null,
      ageMonth: null,
      ageYear: null,
      rest_value: null,
      helpDialog: false,
      newsOpen: false,
      newValueCurrency: 'EUR'
    }
  },
  computed: {
    years () {
      const years = []

      for (let year = moment().year() + 1; year >= 1995; year--) {
        years.push(year)
      }

      return years
    },
    newValueHrk () {
      if (this.newValueCurrency === 'HRK') return this.new_value

      return Math.round(this.new_value * 7.5345)
    },
    months () {
      return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    },
    tax () {
      let ecoTax = null
      let priceTax = null
      let ecoTaxSum = 0
      let priceTaxSum = 0
      let taxSum = 0
      let depr = 0
      let finalTax = 0

      if (this.fuel === 'd') {
        tax[this.newOrOldCalculation].co2.d.forEach(function (item) {
          if (this.co2 > item.min) ecoTax = item
        }.bind(this))
      } else if (this.fuel === 'b') {
        tax[this.newOrOldCalculation].co2.b.forEach(function (item) {
          if (this.co2 > item.min) ecoTax = item
        }.bind(this))
      }

      if (ecoTax) ecoTaxSum = ecoTax.on + (this.co2 - ecoTax.min) * ecoTax.perg

      // price tax
      tax[this.newOrOldCalculation].pc.forEach(function (item) {
        if (this.newValueHrk > item.min) priceTax = item
      }.bind(this))

      if (priceTax) priceTaxSum = priceTax.vn + (this.newValueHrk - priceTax.min) * (priceTax.tax / 100)

      taxSum = ecoTaxSum + priceTaxSum

      if (this.type === 'rabljeno' && this.rest_value && this.newValueHrk) depr = 1 - (this.rest_value * 7.5345) / this.newValueHrk

      if (depr < 0) depr = 0

      finalTax = (1 - depr) * taxSum

      return {
        ecoTax: ecoTaxSum,
        priceTax: priceTaxSum,
        taxSum: taxSum,
        depr: depr,
        finalTax: finalTax
      }
    },
    new_value_input: {
      get: function () {
        if (!this.new_value) return null

        return new Intl.NumberFormat('hr-HR').format(parseInt(this.new_value))
      },
      set: function (value) {
        this.new_value = parseInt(value.split('.').join(''))
      }
    },
    rest_value_input: {
      get: function () {
        if (!this.rest_value) return null

        return new Intl.NumberFormat('hr-HR').format(parseInt(this.rest_value))
      },
      set: function (value) {
        this.rest_value = parseInt(value.split('.').join(''))
      }
    },
    newOrOldCalculation () {
      let newOrOld = 'old'

      if (this.type === 'novo') newOrOld = 'new'

      if (this.ageMonth && this.ageYear) {
        const reg = moment([this.ageYear, this.ageMonth - 1, 1])

        if (reg.isSameOrAfter(moment([2021, 0, 1]))) {
          newOrOld = 'new'
        } else {
          newOrOld = 'old'
        }
      }

      return newOrOld
    }
  },
  watch: {
    ageYear: function () {
      this.calculateRest()
    },
    ageMonth: function () {
      this.calculateRest()
    },
    new_value: function () {
      this.calculateRest()
    },
    newValueCurrency: function (currency) {
      if (!this.new_value || this.new_value === 0) return

      if (currency === 'EUR') {
        this.new_value = this.new_value / 7.5345
      } else {
        this.new_value = this.new_value * 7.5345
      }

      this.$nextTick(() => {
        this.calculateRest()
      })
    },
    type: function () {
      this.calculateRest()
    }
  },
  created () {
    if (!localStorage['uvoz-auta:news-dismissed']) {
      this.newsOpen = true
    }
  },
  mounted () {
    if (window.CVAff) window.CVAff.load()
  },
  updated () {
    if (window.CVAff) window.CVAff.load()
  },
  methods: {
    calculateRest () {
      if (!this.ageYear || !this.ageMonth || this.type === 'novo') return

      const before = moment([this.ageYear, this.ageMonth - 1, 1])
      const now = moment()

      let age = now.diff(before, 'months')

      if (age < 0) age = 0

      if (age === 0) {
        this.rest_value = this.newValueHrk / (this.newValueCurrency === 'EUR' ? 7.5345 : 1)
        return
      }

      let depreciation = 0

      depr.forEach(function (item) {
        if (age >= item.min) depreciation = item.percentage
      })

      console.log('calculates rest value', {
        newValueHrk: this.newValueHrk,
        depreciation: depreciation,
        newValueCurrency: this.newValueCurrency
      })

      this.rest_value = Math.round(depreciation / 100 * this.newValueHrk) / 7.5345
    },
    dismissNews () {
      this.newsOpen = false
      localStorage['uvoz-auta:news-dismissed'] = true
    },
    unDismissNews () {
      this.newsOpen = true
    }
  }

}
</script>
