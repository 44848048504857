import Vue from 'vue'
import VueRouter from 'vue-router'
import PPMV from '../views/PPMV'
import UpravnaPristojba from '../views/UpravnaPristojba'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: PPMV
  },
  {
    path: '/upravna-pristojba',
    component: UpravnaPristojba
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
